
import { Button, Stack, Typography } from "@mui/material";

const SigninPage = (props) => {

  return (
    <Stack direction="row" spacing={3} padding={2}>
      <Typography variant="h6" >
        You must first sign in to use this application.
      </Typography>
      <Button variant="outlined" href={props.loginUrl}>Sign in/up</Button>
      
    </Stack>
  );
};

export default SigninPage;