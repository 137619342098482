

import { DeleteObjectsCommand, PutObjectCommand, ListObjectsV2Command } from "@aws-sdk/client-s3";
import { toast } from "react-toastify";


/**
 * Encapsulates the data model used by the application and wraps all calls to AWS
 * in a common place. The React application subscribes to the model so that it is
 * informed whenever the underlying data changes.
 */
export default class S3API {
  /**
   * @param s3: An Amazon Simple Storage Service (Amazon S3) client.
   * @param ConfigError: An error message that indicates the demo application is not
   *                     configured correctly.
   */
  constructor({
    s3,
    ConfigError,
    DefaultBucketName
  }) {
    this.s3 = s3;
    this.bucketName = DefaultBucketName;
    this.files = [];
    this.modelError = ConfigError;
  }

  /**
   * 
   * @returns 
   */
  async saveFile(filename, file, callback) {
    let bucketName = this.bucketName;
    let prefix = "";
    this.modelError = null;
    try {

      console.log(`Uploading to ${bucketName}/${prefix}${filename}`);
      let resp = await this.s3.send(
        new PutObjectCommand({ Bucket: bucketName, Key: `${prefix}${filename}`, Body: file })
      );

      toast.info("File uploaded successfully.");


    } catch (error) {
      toast.error(error.message);
      console.log(error);
      this.modelError = error.message;
      if (error.Code === "AccessDenied") {
        this.modelError +=
          ". This may mean the file you entered is not present in " +
          "the specified bucket.";
      }
    }

    callback();

  }

  /**
   * 
   * @returns 
   */
  async deleteFiles(listFiles, callback) {
    let bucketName = this.bucketName;
    this.modelError = null;
    try {

      console.log(`Deleting ${listFiles.length} objects in ${bucketName}`);
      console.log(JSON.stringify(listFiles));
      let array = listFiles.map((item)=>{
        return {Key: item}
      })
      console.log(JSON.stringify(array));
      let resp = await this.s3.send(
        new DeleteObjectsCommand({
          Bucket: bucketName,
          Delete: {
            Objects: array,
          },
        })
      );

      toast.info("Files deleted successfully.");


    } catch (error) {
      toast.error(error.message);
      console.log(error);
      this.modelError = error.message;
      if (error.Code === "AccessDenied") {
        this.modelError +=
          ". This may mean the file you entered is not present in " +
          "the specified bucket.";
      }
    }

    callback();

  }


  /**
   * 
   * @returns 
   */
  async listFiles(setState) {
    let bucketName = this.bucketName;
    let contents = [];
    this.modelError = null;
    try {

      console.log(`List objects from ${bucketName}...`);

      const command = new ListObjectsV2Command({
        Bucket: bucketName,
        Prefix: '',
        MaxKeys: 100,
      });

      let isTruncated = true;


      while (isTruncated) {
        const { Contents, IsTruncated, NextContinuationToken } = await this.s3.send(command);
        // const contentsList = Contents.map((c) => c.Key);
        contents = contents.concat(Contents?Contents:[]);
        isTruncated = IsTruncated;
        command.input.ContinuationToken = NextContinuationToken;
      }


    } catch (error) {
      toast.error(error.message);
      console.log(error);
      this.modelError = error.message;
      if (error.Code === "AccessDenied") {
        this.modelError +=
          ". This may mean the file you entered is not present in " +
          "the specified bucket.";
      }
    }

    console.log("Finish listing.");

    this.files = (contents)? contents: [];
    setState(this.files);
  }


}
