import axios from "axios";
import queryString from "query-string";

// const baseURL = "https://generative-demo-api.keepler.io/stage-api/qa";
// const baseURL = "https://azq4sra4wa.execute-api.eu-west-1.amazonaws.com/localhost/qa";
// const baseURL = "http://35.85.63.94/qa";
const baseURL = "https://azq4sra4wa.execute-api.eu-west-1.amazonaws.com/v1/qa";

const axiosClient = axios.create({
  baseURL,
  paramsSerializer: {
    encode: params => queryString.stringify(params)
  }
});

axiosClient.interceptors.request.use(async config => {
  return {
    ...config,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("tkn")}`
    }
  };
});

axiosClient.interceptors.response.use((response) => {
  if (response && response.data) return response.data;
  return response;
}, (err) => {
  throw err.response.data;
});

export default axiosClient;