import { useEffect, useState } from "react";
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Typography} from "@mui/material";

const InstructionPanel = (props) => {

  const [files, setFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);

  useEffect(() => {
    props.s3api.listFiles(setFiles);
  }, []);

  const handleListFiles = () => {
    props.s3api.listFiles(setFiles);
  }

  const handleUpload = (event) => {
    console.log("handleUpload")
    const selectedFile = event.target.files[0];
    props.s3api.saveFile(selectedFile.name, selectedFile, handleListFiles);
  };

  const handleDelete = async () => {
    console.log("handleDelete "+JSON.stringify(filesToDelete));
    props.s3api.deleteFiles(filesToDelete, handleListFiles);
  }

  const handleCheckbox = (event) => {
    let check = event.target.checked;
    let file = event.target.id;
    if (check){
      filesToDelete.push(file);
    }else{
      filesToDelete.filter(el => el !== file);
    }
    setFilesToDelete(filesToDelete);
  };



  return (

    <div >
      <Typography sx={{
        fontWeight: "bold",
        marginBottom: "5px"
      }}>
        Ask a question about Apple's annual reports
      </Typography>
      <Typography component={'span'} className="text-gray-keepler b-white" sx={{
        fontSize: "0.875em",
      }}>
        This tool shows the performance of LLM in Retrieval Augmented Generation (RAG) use cases, where documents are uploaded to generate a knowledge base that can be consulted in a simple and interactive way.
        
      </Typography>
      
    </div>
  );
};

export default InstructionPanel;