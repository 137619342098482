import InstructionPanel from "./InstructionPanel.jsx";
import { chatCompletion } from "../api/chat.api.js";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { Stack, Box, Typography, FormControl, OutlinedInput, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import logo from '../img/logo.png';

const messageType = {
  answer: "answer",
  question: "question"
};

const HomePage = (props) => {
  const inputRef = useRef();
  const chatWrapperRef = useRef();

  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);

  const getAnswer = async () => {
    if (onRequest) return;

    // const newMessages = [...messages, {
    //   type: messageType.question,
    //   content: question
    // }];
    const newMessages = []

    setMessages(newMessages);
    // setQuestion("");
    setOnRequest(true);

    const { response, err } = await chatCompletion(question);

    if (response) {
      setMessages([...newMessages, {
        type: messageType.answer,
        content: response
      }]);
      setOnRequest(false);
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
    // TODO: añadir else

    if (err) {
      toast.error(err.message);
      setOnRequest(false);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) getAnswer();
  };

  useEffect(() => {
    setTimeout(() => {
      chatWrapperRef.current.addEventListener("DOMNodeInserted", e => {
        e.currentTarget.scroll({
          top: e.currentTarget.scrollHeight,
          behavior: "smooth"
        });
      });
    }, 200);

  }, []);

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: "100%" }}
    >

      {/* Header */}
      <Box
        className="darkest-keepler"
        sx={{
          zIndex: 2,
          width: "100%",
          height: "80px",
          display: "flex",
          padding: "1.5rem",
        }}>
        <Box sx={{
          position: "relative",
          paddingX: 2,
          maxWidth: "md",
          minWidth: "325px"
        }}>
          <img src={logo} height="35"
            style={{
              float: "left",
              marginRight: "20px"
            }} />
          <Typography
            variant="h6"
            fontWeight="700"
            sx={{ float: "left" }}
          >
            DoK GenAI Demo
          </Typography>
        </Box>
        <Box className="top-question-box-keepler"
          width="100%"
          maxWidth="md"
        >
          <FormControl fullWidth variant="outlined" className="dark-keepler">
            <OutlinedInput
              inputRef={inputRef}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px"
                }
              }}
              endAdornment={
                onRequest ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} style={{ cursor: "pointer" }}
                    onClick={getAnswer}/>
                )
              }
              autoFocus
              disabled={onRequest}
              onKeyUp={onEnterPress}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Ask something..."
            />
          </FormControl>
        </Box>
      </Box>
      <div className="darker-keepler" ref={chatWrapperRef}
        style={{
          height: "100%",
          position: "fixed",
          zIndex: 1,
          width: "100%",
          overflowY: "auto",
          paddingTop: "80px",
          paddingBottom: "90px"
        }}>
        <Box className="dark-keepler sidebar-keepler">
          <InstructionPanel s3api={props.s3api} />
          {/* <FileLoader s3api={props.s3api} /> */}
        </Box>
        <Box className="main-content-keepler" >
          <Box sx={{
            // display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            maxWidth: "md",
            width: "100%"
          }}>
            {messages.map((item, index) => (
              <Box key={index} padding={1}>
                <Box sx={{
                  padding: 2
                }}>
                  <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {item.content}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    </Stack >
  );
};

export default HomePage;