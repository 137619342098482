import "react-toastify/dist/ReactToastify.css";

import HomePage from "./question_view/HomePage";
import SigninPage from "./SigninPage";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import './css/custom.css';


function App(props) {
  const theme = createTheme({
    palette: { mode: "dark" },
    typography: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
        position="bottom-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
      {props.isSignedIn ?
        <HomePage s3api={props.s3api} />
        :
        <SigninPage loginUrl={props.config.LoginUrl} />
      }

    </ThemeProvider>
  );
}

export default App;
