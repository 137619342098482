
/**
 * The main entry point for the React application.
 *
 * Gets the Amazon Cognito token from the URL query parameters and uses it to
 * initialize AWS client objects.
 *
 * Creates a model layer that handles moving data between AWS and the user interface.
 *
 * Subscribes the main application render function to the model so that the application
 * can be rendered whenever the underlying model changes.
 */

import React from "react";
import ReactDOM from "react-dom/client";
import { Config } from "./Config.js";
import { awsFactory } from "./AwsFactory.js";
import App from "./App.jsx";
import S3API from "./api/s3.api.js";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const params = new URLSearchParams(window.location.hash.slice(1));
const idToken = params.get("id_token");
localStorage.setItem("tkn", idToken);
let awsClients = null;
if (typeof Config.CognitoId !== "undefined") {
  awsClients = awsFactory({
    cognitoId: Config.CognitoId,
    cognitoToken: idToken,
    cognitoIdentityPoolId: Config.CognitoIdentityPoolId,
    deployRegion: Config.DeployRegion,
  });
}

const s3api = new S3API({
  ...awsClients,
  ...Config
});


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App
        isSignedIn={idToken !== null}
        config={Config}
        s3api={s3api} />
  </React.StrictMode>,
);

