import axiosClient from "./axios.client.js";

export const chatCompletion = async (prompt) => {
  try {

    const response = await axiosClient.post("", {query: prompt});
    console.log(response)

    // const response = {
    //   "request_id": "bf508d65-6036-4592-bedf-c7468d8ec9c1",
    //   "output_text": "\nThe CEO of Keepler is Juan Maria Aramburu.\n\nSOURCES:\n- Corporate Information: https://doc.keepler.io/books/corporate-information/page/governance-of-the-company\n- Organizational Circles Structure: https://doc.keepler.io/books/corporate-information/page/organizational-circles-structure\n- Email: juan.maria.aramburu@keepler.io",
    //   "dont_know": false,
    //   "used_tokens": 1667
    // }

    return { response };
  } catch (err) {
    return { err };
  }
};